
.active-chat {
	background: #e8f6ff;
}

.incoming-msg-img {
	display: inline-block;
	/* width: 6%; */
    width: 16%;
}

.incoming-msg-img img {
	width: 100%;
}

.received-msg {
	display: inline-block;
	padding: 0 0 0 10px;
	vertical-align: top;
	/* width: 92%; */
    width: 69%;
}

.received-withd-msg p {
	background: #F2F4F7 none repeat scroll 0 0;
	border-radius: 0 15px 15px 15px;
	color: #101828;
	font-size: 14px;
	margin: 0;
	padding: 5px 10px 5px 12px;
	width: 100%;
}

.time-date {
	color: #747474;
	display: block;
	font-size: 12px;
	margin: 8px 0 0;
}
.sent-msg p {
	background:#08783D;
	border-radius: 12px 15px 15px 0;
	font-size: 14px;
	margin: 0;
	color: #fff;
	padding: 5px 10px 5px 12px;
	width: 100%;
}

.outgoing-msg {
	overflow: hidden;
	margin: 26px 0 26px;
}

.sent-msg {
	float: right;
	width: 46%;
}

.input-msg-write input {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: medium none;
	color: #4c4c4c;
	font-size: 15px;
	min-height: 48px;
	width: 100%;
	outline:none;
}

.type-msg {
	border: 1px solid #c4c4c4;
    border-radius: 8px;
	position: relative;
    width: 340px;
}

.messaging {
	padding: 0 0 50px 0;
}

.msg-send-btn {
	background: #08783D none repeat scroll 0 0;
	border:none;
	border-radius: 8px;
	color: #fff;
	cursor: pointer;
	font-size: 15px;
	height: 44px;
	/* position: absolute;
	right: 8px;
	top: 9px; */
	width: 44px;
}
.msg-history {
    width: 460px;
    height: 516px;
}