* {
    font-family: 'Work Sans', sans-serif !important;
}
.d-more-btn {
    padding: 5px 17px;
    font-size: 13px;
}
.activate-text {
    font-family: Work Sans;
font-size: 18px;
font-style: normal;
font-weight: 400;
}
.activate-title {
    text-align: center;
/* H4/Sbold */
font-family: Work Sans;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 120%;
}
.header {
    z-index: 997;
    padding: 10px 0;
    background: #fff;
    height: 74px;
}

.navbar ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar li {
    position: relative;
}

.navbar a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 10px 30px;
    color: #667085;
    /* font-family: 'Komet'; */
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
}

.navbar .signin-btn {
    padding: 8px 30px;
    background: #EBF5F0;
    border-radius: 5px;
    margin-left: 30px;
    color: #08783D;
}

.navbar .signup-btn {
    padding: 8px 30px;
    background: #08783D;
    color: #fff;
    border-radius: 5px;
    margin-left: 30px;
}

.mobile-nav-toggle {
    color: #012970;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.hero {
    width: 100%;
    height: 100vh;
    background: linear-gradient(0deg, rgba(57, 147, 100, 0.25) 0%, rgba(206, 228, 216, 0.25) 77.6%);
}

.hero h1 {
    font-family: 'Abel';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -0.02em;
    color: #101828;
}

.hero p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
}

.hero a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 10px 30px;
    color: #667085;
    /* font-family: 'Komet'; */
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
}

.hero-text {
    margin-top: 25px;
}

.hero .signin-btn {
    padding: 8px 40px;
    background: #EBF5F0;
    border-radius: 5px;
    color: #08783D;
    /* margin-left: 30px; */
}

.hero .signup-btn {
    padding: 8px 30px;
    background: #08783D;
    color: #fff;
    border-radius: 5px;
}

.videosection,
.products,
.whatwedo,
.resources {
    /* height: 800px; */
    height: auto;
    background: #fff;
    /* padding: 70px 64px 84px 64px; */

    padding: 70px 48px 84px 48px;
}

.faqs {
    padding: 45px 28px 45px 28px;
}

.products {
    height: auto;
    background: linear-gradient(0deg, rgba(57, 147, 100, 0.25) 0%, rgba(206, 228, 216, 0.25) 77.6%)
}

.whatwedo {
    height: auto;
    background: #fff;
    ;
}

.dreamjob {
    height: 300px;
    background: linear-gradient(0deg, rgba(57, 147, 100, 0.25) 0%, rgba(206, 228, 216, 0.25) 77.6%), url('../../public/assets/images/issue.svg');
    /* background-image: url('../../public/assets/images/issue.svg'); */
    position: relative;
    text-align: center;
}

.videosection h1,
.products h1,
.whatwedo h1,
.dreamjob h1,
.resources h1 {
    font-family: 'Abel';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1D2939;
}

.videosection p,
.whatwedo p {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
}

.dreamjob p {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #475467;
}

.dreamjob .inner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dreamjob .btn-lodge {
    padding: 8px 30px;
    background: #08783D;
    color: #fff;
    border-radius: 5px;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track  */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #EBF5F0;
    border-radius: 10px;
}

/* Handle  */
::-webkit-scrollbar-thumb {
    background: rgb(8, 120, 61);
    border-radius: 10px;
}

/* Handle on hover  */
::-webkit-scrollbar-thumb:hover {
    background: rgb(8, 120, 61);
}

.error {
    color: rgb(249, 112, 102);
}

.mcard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 24px;
    /* width: 334px; */
    /* height: 489px; */
    background: #FCFCFD;
    border: 1px solid #EAECF0;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 10px;
}

.video-card .video-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 20px;
    /* width: 75px;
height: 28px; */
    font-family: 'Komet';
    background: #EBF5F0;
    border-radius: 97px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #08783D;
}

.video-card .title {
    font-family: 'Abel';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 285px;
}

.video-card {
    width: 320px;
}

.video-card .description {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;

}

.video-card .more-text {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #08783D;

}

.dreamjob .dreamjob-btn {
    align-items: center;
    padding: 12px 10px;
    background: #08783D;
    border-radius: 10px;
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-decoration: none;
    padding: 8px 30px;
    background: #08783D;
    color: #fff;
    border-radius: 5px;
}

.dreamjob .inner-wrapper .title {
    font-family: 'Abel';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #08783D;
    text-wrap: nowrap;
}

.footer {
    /* height: 569px; */
    height: auto;
    background: url('../../public/assets/images/footer-curve.svg') no-repeat 0 53px, linear-gradient(26.57deg, #022412 8.33%, #08783D 91.67%);
    background-size: contain;
    position: relative;
    padding-top: 93px;
}

.footer .descr {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 28px;
    text-align: center;
    color: #EBF5F0;
    margin-top: 2rem;
}

/* .social-btn img {
    cursor: pointer;
} */
.question-wrapper {
    color: #EAECF0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: auto;
}

.footer-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    list-style: none;
}

.footer-link a {
    display: flex;
    align-items: center;
    padding: 0px 55px 10px 30px;
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #EBF5F0;
    text-decoration: none;
    white-space: nowrap;
}

.footer .copywrite {
    color: #EBF5F0;
}

.footer .copywrite-text {
    text-align: center;
}

.banner {
    height: 500px;
    background: linear-gradient(0deg, rgba(57, 147, 100, 0.25) 0%, rgba(206, 228, 216, 0.25) 77.6%),
        url('../../public/assets/images/banner-left.svg') no-repeat left,
        url('../../public/assets/images/banner-right.svg') no-repeat right;


}

.banner .badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 20px;
    /* width: 115px;
height: 28px; */
    background: #F6EAEB;
    border-radius: 97px;
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #A92F34;
}

.banner .search-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 20px;
    /* width: 271px; */
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #98A2B3;
}

.banner h1 {
    font-family: 'Abel';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #101828;
}

.banner .search-wrapper input {
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    padding: 11px;
}
.ant-picker-input .ant-picker-input-active input {
    text-align: end;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
}

.more-btn, .more-btn-outline {
    align-items: center;
    padding: 12px 10px;
    background: #08783D;
    border-radius: 10px;
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    padding: 8px 30px;
    background: #08783D;
    color: #fff;
    border-radius: 5px;
}
.more-btn-outline {
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    color: #344054;
}
.more-btn:hover, .more-btn-outline:hover  {
    background: #EBF5F0;
    color: #08783D;
}

.btn.more-btn.active,
.btn.more-btn:active {
    background-color: #EBF5F0;
    border-color: #EBF5F0;
    color: #08783D;
}

.accordion-button:not(.collapsed) {
    color: rgb(8, 120, 61);
    background-color: #EBF5F0;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-flush .accordion-item:first-child {
    border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-flush .accordion-item:last-child {
    border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-button:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.onboarding {
    height: auto;
}

.onboarding .left-banner {
    height: 40vh;
    background: url('../../public/assets/images/left-banner.svg') no-repeat left, linear-gradient(26.57deg, #022412 8.33%, #08783D 91.67%);
    background-size: contain;
    padding: 82px 13px 0px 30px;
    display: none;
}

.left-banner .title {
    font-family: 'Abel';
    font-style: normal;
    font-weight: 400;
    /* font-size: 60px; */
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-align: right;
}

.signup input {
    /* padding: 10px 14px; */
    background: #FFFFFF;
    border: 0.5px solid #D0D5DD;
    border-radius: 8px;
}

.mask-password {
    position: relative;
    cursor: pointer;
}

.password {
    position: absolute;
    top: 57%;
    right: 4%;
    cursor: pointer;
    color: lightgray;
}

.right-banner {
    margin-left: 0;
}

.right-banner .title {
    font-family: 'Abel';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: #1D2939;
}

.right-banner .subtitle {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
}

.form-label {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1D2939;
}

.close-btn-wrapper {
    width: 100%;
}

.close-btn {
    width: 40px;
    height: 40px;
    background: #F2F4F7;
    border-radius: 20px;
    cursor: pointer;
    /* position: absolute;
top: 5;
right: 5; */
}

.accountverified-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../public/assets/images/Background-S.svg') no-repeat, linear-gradient(26.57deg, #022412 8.33%, #08783D 91.67%);
    background-size: contain;
}

.card-subtitle-modal {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #1D2939;
}

.card-title-modal {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #1D2939;
}

.complaint-result {
    margin-top: 8rem;
    font-family: 'Komet';
    font-style: normal;
    font-weight: 700;
    /* font-size: 18px; */
    line-height: 28px;
    color: #101828;
}

.complaint-result .title {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #1D2939;
}

.complaint-result .subtitle {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #475467;
}

.complaint-result .attribute {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #101828;
}

.complaint-result .value {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #475467;
}

:where(.css-dev-only-do-not-override-o565yi).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #08783D;
}

:where(.css-dev-only-do-not-override-o565yi).ant-steps .ant-steps-item-subtitle {
    display: inline;
    margin-inline-start: 0px;
}

:where(.css-dev-only-do-not-override-o565yi).ant-steps .ant-steps-item-icon {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #667085;
    display: flex;
    justify-content: center;
    align-items: center;
}

.steps .title {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1D2939;
}

.steps .subtitle {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #98A2B3;
}

.steps .description {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
}

.complaint-result .complaint-image {
    width: 82px;
    height: 82px;
    border: 1px solid #D0D5DD;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
}

.complaint-result .bellow-text {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

.complaint-result .bellow-text a {
    text-decoration: none;
    color: #08783D;
}

.mobile-navbar {
    position: fixed;
    overflow: hidden;
    /* background-color: rgba(138, 187, 42, 0.3); */
    background-color: rgb(235, 245, 240);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.3s;
}

.mobile-navbar .signin-btn {
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 8px 71px;
}

.signup-mobile {
    --bs-gutter-x: 3rem;
}
.c-btn-wrapper {
    margin-bottom: 20px;
}
.d-more-btn {
    padding: 5px 17px;
       font-size: 13px;
   }
@media (min-width: 992px) {
    .d-more-btn {
        padding: 5px 30px;
    }
    .signup-mobile {
        --bs-gutter-x: 0rem;
    }

    .close-btn-wrapper {
        width: 90%;
    }

    .modal-body {
        margin-top: -3.5rem;
    }

    .onboarding .left-banner {
        height: 100vh;
        background: url('../../public/assets/images/left-banner.svg') no-repeat bottom, linear-gradient(26.57deg, #022412 8.33%, #08783D 91.67%);
        background-size: contain;
        padding: 85px 20px 0px 30px;
        position: fixed;
        top: 0;
        bottom: 0;
        display: flex;
    }

    .right-banner {
        margin-left: 25%;
    }

    .left-banner .title {
        line-height: 55px;
        text-align: left;
    }

    .footer .descr {
        font-weight: 500;
        font-size: 18px;
    }

    .footer-link {
        flex-direction: row;
        list-style: none;
    }

    .footer .copywrite-text {
        text-align: end;
    }

    .question-wrapper {
        color: #EAECF0;
        display: flex;
        justify-content: space-between;
    }

    /* .mcard {
        width: 320px;
    } */

    .header {
        /* padding: 10px 114px; */
        /* padding: 10px; */
    }

    .videosection,
    .products,
    .whatwedo,
    .resources,
    .faqs {
        padding: 70px 88px 84px 88px;
    }

    .footer .copywrite {
        /* padding: 28px 88px 0px 88px; */
        font-family: 'Komet';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #EBF5F0;
    }

    .copywrite .phone-text .answer {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .copywrite .phone-text .question {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    /* .hero {
        padding: 45px 0 45px 114px;
    } */
    .hero .signin-btn {
        padding: 8px 51px;
        background: #EBF5F0;
        border-radius: 5px;
        color: #08783D;
        margin-left: 30px;
    }

    .hero-text {
        padding-right: 3em;
    }

    .hero h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .hero p {
        font-size: 18px;
        line-height: 28px;
    }

    element.style {
        color: rgb(8, 120, 61);
    }

    .videosection h1,
    .products h1,
    .whatwedo h1,
    .dreamjob h1 {
        font-size: 36px;
    }

    .accountverified-container {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../../public/assets/images/Background-S.svg') no-repeat, linear-gradient(26.57deg, #022412 8.33%, #08783D 91.67%);
        background-size: contain;
    }
}

@media (max-width: 991px) {
    .navbar ul {
        display: none;
    }

    .mobile-nav-toggle {
        display: block;
    }

    .logo {
        width: 50px;
        height: 50px;
    }

    .hero {
        padding: 10px 8px 10px 10px;
    }

    .hero .hero-btn {
        justify-content: space-between;
    }
}

.mobile-navbar ul {
    display: block;
    position: absolute;
    background-color: #fff;
    top: 15px;
    left: 15px;
    bottom: 15px;
    right: 15px;
    padding: 25px 0;
    border-radius: 10px;
    transition: .3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.mobile-navbar .mobile-nav-toggle {
    position: absolute;
    top: 30px;
    right: 30px;
    color: #ffff;
}

@media (width: 768px) {
    .hero {
        width: 100%;
        height: 50vh;
    }
}